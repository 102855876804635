html,
body,
#root {
	height: 100%;
}
body {
	height: 100%;
	margin: 0;
	background-color: #fff;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiListItem-secondaryAction,
.MuiListItemSecondaryAction-root {
	cursor: pointer;
}
:focus-visible {
	outline: none;
}

.facebook-button {
	width: 100%;
	height: 100%;
	border: none;
	background-color: #4c69ba;
	border-radius: 4px;
	font-family: Helvetica, sans-serif;
	font-weight: 700;
	-webkit-font-smoothing: antialiased;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: calc(0.27548vw + 12.71074px);
}

.facebook-button i {
	margin-right: 10px;
}
