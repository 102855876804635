.NotificationStack {
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 14px;
}

.NotificationStackItem {
	display: flex;
	border-radius: 5px;
	background-color: rgba(100, 100, 100, 0.8);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	padding: 10px;
	margin-bottom: 15px;
	color: white;
}

.NotificationStackItemTitle {
	font-size: 12px;
}

.NotificationStackItemAvatar {
	width: 40px;
	height: 40px;
	border-radius: 5px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.NotificationStackItemInfo {
	flex: 1;
	margin-right: 15px;
}
